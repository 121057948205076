<template>
  <Modal name="common_error" title="Ошибка!" size="lg" @closeModal="closeModal">
    <template v-slot:content>
      <div class="success_modal">
        <p v-if="text" class="modal__text">
          {{ text }}
        </p>

        <div v-if="errors && errors.length">
          <template v-if="errors[0].explain">
            <ul>
              <li>Не удалось отправить данные, проверьте заполнение полей:</li>

              <li v-if="errors[0].explain.q474"><i>Имя</i></li>

              <li v-if="errors[0].explain._member_email"><i>E-mail</i></li>

              <li v-if="errors[0].explain.q772">
                <i>Cогласие на получение рекламных сообщений</i>
              </li>

              <li v-if="errors[0].explain.q786">
                <i>Подтверждение достижения возраста 18 лет</i>
              </li>

              <li v-if="errors[0].explain.q517">
                <i>Подтверждение согласия с правилами</i>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      errors: [],
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      this.text = event.params?.text;
      this.errors = event.params?.errors;
    },
  },
};
</script>
